<template>
  <section class="create-quiz-wrapper">
    <!-- <div id="scene-container" ref="sceneContainer"></div> -->
    <div class="container-filter w-100 pt-5 pb-3 d-flex">
      <div class="child-nav d-flex mt-4 ml-auto justify-content-end mr-5">
        <input 
          type="text"
          class="form-control morphism w-25 mr-3 text-white"
          :placeholder="$store.state.createCurrentSoal+'/'+lengthQuestionAI"
          disabled>
          <!-- <div class="morphism">
            <span>{{ $store.state.createCurrentSoal }}</span>
          </div> -->
        <select name="" id="poin" v-model="$store.state.createData.point" class="form-control mr-3 w-75">
          <option :value="5">5 Points</option>
          <option :value="10">10 Points</option>
          <option :value="15">15 Points</option>
          <option :value="20">20 Points</option>
        </select>
        <select name="" v-model="$store.state.createData.duration" id="time" class="form-control mr-3 w-75">
          <option :value="10">10 Seconds</option>
          <option :value="30">30 Seconds</option>
          <option :value="60">60 Seconds</option>
          <option :value="90">90 Seconds</option>
          <option :value="120">120 Seconds</option>
        </select>
        <select
          name=""
          id="time"
          v-model="$store.state.createData.question_type"
          class="form-control w-100"
          @change="tipeQuestion"
        >
          <option value="Multiple Choice">Multiple Choice</option>
          <option value="True or False">True / False</option>
          <option value="Fill in the Blank">Short Answers</option>
          <!-- <option value="Match">Menjodohkan</option> -->
        </select>
        <!-- <button v-if="listQuestion.length == 10" type="button" class="btn btn-primary text-center" @click="showAlert">
          <img src="@/assets/images/plus-white.svg" class="mr-2" />Tambah
          Pertanyaan
        </button>
        <button v-else type="button" class="btn btn-primary text-center" @click="addIndex">
          <img src="@/assets/images/plus-white.svg" class="mr-2" />Tambah
          Pertanyaan
        </button> -->
      </div>
    </div>
    <div class="container-fluid">
      <div class="w-100">
        <div class="d-flex">
            <!-- <div class="choice w-25 ml-auto mt-5 d-flex justify-content-center align-items-center" style="height: 15rem;">
              <div class="">
                <img src="https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg" alt="" class="img-question">
              </div>
            </div> -->
          <div class="container-question ml-auto">
            <div class="question w-100">
              <div class="question-text mb-2" :style="{ zIndex: 4 }">
                <b-alert variant="danger" :show="$store.state.showAlertSoalCreate" fade>
                  {{ $store.state.responseSoal }}   
                </b-alert>
                <div class="d-flex">
                  <div v-if="$store.state.createData.question_type != 'Match'" class="mx-3" :style="{ marginTop: '2.5rem' }">
                    <div v-if="$store.state.createData.image_url == null || $store.state.createData.image_url == ''" class="d-flex flex-column bd-highlight">
                      <!-- IMAGE INSERT -->
                      <img v-if="$store.state.createData.voice != null && $store.state.createData.voice != '' || $store.state.createData.video != null && $store.state.createData.video != ''" src="@/assets/images/icons/ic_img.svg" alt="img" type="button" class="feat-button cursor-disabled">
                      <img v-else @click="$bvModal.show('modal-insert-img')" src="@/assets/images/icons/ic_img.svg" alt="img" type="button" class="feat-button">
                      
                      <!-- VOICE INSERT -->
                      <img v-if="$store.state.createData.video != null && $store.state.createData.video != ''" src="@/assets/images/icons/ic_mic.svg" alt="mic" type="button" class="mt-2 feat-button cursor-disabled">
                      <img v-else src="@/assets/images/icons/ic_mic.svg" @click="$bvModal.show('rec-voice')" alt="mic" type="button" class="mt-2 feat-button">

                      <!-- VIDEO INSERT -->
                      <img v-if="$store.state.createData.voice != null && $store.state.createData.voice != ''" src="@/assets/images/icons/ic_video.svg" alt="video" type="button" class="mt-2 feat-button cursor-disabled">
                      <img v-else src="@/assets/images/icons/ic_video.svg" @click="$bvModal.show('insert-video')" alt="video" type="button" class="mt-2 feat-button">
                    </div>
                    <!-- <button v-if="$store.state.createData.image_url == null || $store.state.createData.image_url == ''" type="button" class="btn btn-outline-light" @click="$bvModal.show('modal-insert-img')"><b-icon icon="images"></b-icon></button> -->
                    <div v-else>
                      <img :src="$store.state.createData.image_url" :alt="$store.state.createData.image_url" @click="$bvModal.show('modal-insert-img')" class="img-question">
                      <div class="d-flex justify-content-center mt-2">
                        <button type="button" class="btn btn-sm btn-outline-light mr-2" @click="deleteImage"><b-icon icon="trash"></b-icon></button>
                        <button type="button" class="btn btn-sm btn-outline-light" @click="$bvModal.show('modal-insert-img')"><b-icon icon="pencil-square"></b-icon></button>
                      </div>
                    </div>
                  </div>
                  <div v-if="$store.state.createData.voice != null && $store.state.createData.voice != ''" class="w-100">
                    <audio :src="$store.state.createData.voice" controls></audio>
                    <img src="@/assets/images/icons/ic_trash_question.svg" alt="delete" class="cursor-pointer" @click="deleteQuestionVoice">
                  </div>
                  <div v-else-if="$store.state.createData.video != null && $store.state.createData.video != ''" class="w-100 mx-5 my-2">
                    <iframe width="100%" height="300" :src="$store.state.createData.video" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <img src="@/assets/images/icons/ic_trash_question.svg" alt="delete" class="cursor-pointer ml-auto" @click="deleteQuestionVideo">
                  </div>
                  <div v-else class="w-100">
                    <CreatorTextEditor
                      v-if="$store.state.createData.question_type != 'Fill in the Blank'"
                      type="question"
                      placeholder="Type-in your question here ..."
                      v-model="$store.state.createData.question"
                      @update="cekQuestionLenght"
                    />
                    <CreatorTextEditor
                      v-else
                      type="question"
                      placeholder="Type-in your question here ..."  
                      v-model="question_isian_singkat"
                      @update="cekQuestionLenght"
                    />
                  </div>
                  <!-- <textarea v-else v-model="question_isian_singkat" class="form-control mt-2" style="background: transparent; color: white" id="question"></textarea> -->
                </div>
                <p class="text-right mb-0 mr-2 text-white mt-2">Characters : {{ 120 - lengthQuestion($store.state.createData.question) }}</p>
              </div>
              <div class="row choice-wrapper">
                <template v-if="$store.state.createData.question_type == 'Multiple Choice'">
                  <div class="col-6 p-2" :style="{ zIndex: 3 }">
                    <div class="py-2 px-4" :class="$store.state.opsi1 == true || $store.state.opsi1 == '1' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer1Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi1"
                          vs-value="1"
                          :disabled="$store.state.opsi2 != null || $store.state.opsi3 != null || $store.state.opsi4 != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 1 here ..."
                          v-model="$store.state.createData.option_1"
                          @update="cekAnswer1Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer1($store.state.createData.option_1) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 2 }">
                    <div class="py-2 px-4" :class="$store.state.opsi2 == true || $store.state.opsi2 == '2' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer2Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi2"
                          vs-value="2"
                          :disabled="$store.state.opsi1 != null || $store.state.opsi3 != null || $store.state.opsi4 != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 2 here ..."
                          v-model="$store.state.createData.option_2"
                          @update="cekAnswer2Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer2($store.state.createData.option_2) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 1 }">
                    <div class="py-2 px-4" :class="$store.state.opsi3 == true || $store.state.opsi3 == '3' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer3Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi3"
                          :disabled="$store.state.opsi1 != null || $store.state.opsi2 != null || $store.state.opsi4 != null"
                          vs-value="3"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 3 here ..."
                          v-model="$store.state.createData.option_3"
                          @update="cekAnswer3Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer3($store.state.createData.option_3) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 0 }">
                    <div class="p-2 py-2 px-4" :class="$store.state.opsi4 == true || $store.state.opsi4 == '4' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer4Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi4"
                          :disabled="$store.state.opsi1 != null || $store.state.opsi2 != null || $store.state.opsi3 != null"
                          vs-value="4"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 4 here ..."
                          v-model="$store.state.createData.option_4"
                          @update="cekAnswer4Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer4($store.state.createData.option_4) }}</p>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else-if="$store.state.createData.question_type == 'True or False'">
                  <div class="col-6 p-2" :style="{ zIndex: 1 }">
                    <div class="p-2 position-relative" :class="$store.state.opsi1 == true || $store.state.opsi1 == '1' ? 'true-choice' : 'choice'">
                      <vs-checkbox
                        color="success"
                        v-model="$store.state.opsi1"
                        vs-value="1"
                        class="position-absolute checkbox-choice"
                      ></vs-checkbox>
                      <CreatorTextEditor
                        type="answer"
                        v-model="$store.state.createData.option_1"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 0 }">
                    <div class="p-2 py-2 px-4 position-relative" :class="$store.state.opsi2 == true || $store.state.opsi2 == '2' ? 'true-choice' : 'choice'">
                      <vs-checkbox
                        color="success"
                        v-model="$store.state.opsi2"
                        vs-value="2"
                        class="position-absolute checkbox-choice"
                      ></vs-checkbox>
                      <CreatorTextEditor
                        type="answer"
                        v-model="$store.state.createData.option_2"
                        readonly
                      />
                    </div>
                  </div>
                </template>

                <template v-else-if="$store.state.createData.question_type == 'Match'">
                  <!-- QUESTION -->
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-blue">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.createData.option_1 == null || $store.state.createData.option_1 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(1)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.createData.img_option_1 != null && $store.state.createData.img_option_1 != ''" :src="$store.state.createData.img_option_1" alt="1" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.createData.img_option_1 == null || $store.state.createData.img_option_1 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.createData.option_1" placeholder="teks 1" name="teks1q" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-green">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.createData.option_2 == null || $store.state.createData.option_2 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(2)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.createData.img_option_2 != null && $store.state.createData.img_option_2 != ''" :src="$store.state.createData.img_option_2" alt="2" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.createData.img_option_2 == null || $store.state.createData.img_option_2 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.createData.option_2" placeholder="teks 2" name="teks2q" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-danger">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.createData.option_3 == null || $store.state.createData.option_3 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(3)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.createData.img_option_3 != null && $store.state.createData.img_option_3 != ''" :src="$store.state.createData.img_option_3" alt="3" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.createData.img_option_3 == null || $store.state.createData.img_option_3 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.createData.option_3" placeholder="teks 3" name="teks3q" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-warning">
                      <div class="p-3 h-100 mx-2">
                        <div class="text-left">
                          <img v-if="$store.state.createData.option_4 == null || $store.state.createData.option_4 == ''" src="@/assets/images/icons/img-grup.svg" alt="img" type="button" @click="inserImgMenjodohkan(4)">
                          <img v-else src="@/assets/images/icons/img-grup.svg" alt="img" type="button" style="cursor: not-allowed">
                        </div>
                        <img v-if="$store.state.createData.img_option_4 != null && $store.state.createData.img_option_4 != ''" :src="$store.state.createData.img_option_4" alt="4" class="img-fluid mt-3" style="max-height:7.7rem; max-width:12rem;">
                        <div v-if="$store.state.createData.img_option_4 == null || $store.state.createData.img_option_4 == ''" class="mx-auto my-5">
                          <input type="text" autocomplete="off" v-model="$store.state.createData.option_4" placeholder="teks 4" name="teks4q" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ANSWER -->
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-blue">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.createData.match_1" placeholder="teks 1" name="teks1a" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-green">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.createData.match_2" placeholder="teks 2" name="teks2a" />
                      </div>
                    </div>
                  </div>  
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-danger">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.createData.match_3" placeholder="teks 3" name="teks3a" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-1 mb-3">
                    <div class="choice-menjodohkan bg-warning">
                      <div class="p-3 d-flex align-items-center justify-content-center h-100 ml-2">
                        <input type="text" autocomplete="off" v-model="$store.state.createData.match_4" placeholder="teks 4" name="teks4a" />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else-if="$store.state.createData.question_type == 'Fill in the Blank'">
                  <div class="col-6 p-2" :style="{ zIndex: 3 }">
                    <div class="p-2" :class="$store.state.opsi1 == true || $store.state.opsi1 == '1' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer1Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi1"
                          vs-value="1"
                          :disabled="$store.state.opsi2 != null || $store.state.opsi3 != null || $store.state.opsi4 != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 1 here ..."
                          v-model="$store.state.createData.option_1"
                          @update="cekAnswer1Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer1($store.state.createData.option_1) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 2 }">
                    <div class="p-2" :class="$store.state.opsi2 == true || $store.state.opsi2 == '2' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer2Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi2"
                          vs-value="2"
                          :disabled="$store.state.opsi1 != null || $store.state.opsi3 != null || $store.state.opsi4 != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 2 here ..."
                          v-model="$store.state.createData.option_2"
                          @update="cekAnswer2Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer2($store.state.createData.option_2) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 1 }">
                    <div class="p-2" :class="$store.state.opsi3 == true || $store.state.opsi3 == '3' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer3Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi3"
                          :disabled="$store.state.opsi1 != null || $store.state.opsi2 != null || $store.state.opsi4 != null"
                          vs-value="3"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 3 here ..."
                          v-model="$store.state.createData.option_3"
                          @update="cekAnswer3Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer3($store.state.createData.option_3) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2" :style="{ zIndex: 0 }">
                    <div class="p-2" :class="$store.state.opsi4 == true || $store.state.opsi4 == '4' ? 'true-choice' : 'choice'">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer4Create" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="success"
                          v-model="$store.state.opsi4"
                          :disabled="$store.state.opsi1 != null || $store.state.opsi2 != null || $store.state.opsi3 != null"
                          vs-value="4"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <CreatorTextEditor
                          type="answer"
                          placeholder="Type-in option 4 here ..."
                          v-model="$store.state.createData.option_4"
                          @update="cekAnswer4Length"
                        />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer4($store.state.createData.option_4) }}</p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <!-- USER SUBSCRIBE CHECK -->
              <div>
                <div v-if="$store.state.showAlertSoalCreate == false && $store.state.showAlertAnswer1Create == false && $store.state.showAlertAnswer2Create == false && $store.state.showAlertAnswer3Create == false && $store.state.showAlertAnswer4Create == false" class="d-flex justify-content-end mb-5">
                    <!-- PREV BUTTON -->
                    <button v-if="$store.state.createCurrentSoal == 1 || $store.state.createCurrentSoal == 0 " style="cursor : not-allowed" class="btn btn-light mr-2 disabled"><b-icon icon="chevron-left"></b-icon></button>
                    <button v-else class="btn btn-light mr-2" @click="renderSoal($store.state.createCurrentSoal -1)"><b-icon icon="chevron-left"></b-icon></button>
                    <!-- NEXXT BUTTON -->
                    <button v-if="$store.state.listCreateQuestion !=null && $store.state.createCurrentSoal < $store.state.listCreateQuestion.length" class="btn btn-light" @click="renderSoal($store.state.createCurrentSoal+1)"><b-icon icon="chevron-right"></b-icon></button>
                    <!-- <button v-else-if="$store.state.createCurrentSoal == 10 " style="cursor : not-allowed" class="btn btn-light disabled"><b-icon icon="chevron-right"></b-icon></button> -->
                    <button v-else-if="$store.state.listCreateQuestion !=null && $store.state.createCurrentSoal >= $store.state.listCreateQuestion.length " class="btn btn-light" @click="addIndex()"><b-icon icon="chevron-right"></b-icon></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal insert image for questions -->
    <b-modal
      id="modal-insert-img"
      ref="modal"
      centered 
      @ok="saveImage"
    >
      <form ref="form">
        <b-form-group
          label="Input image URL"
          label-for="image-input"
        >
          <b-form-input
            id="image-input"
            v-model="image_url"
            required
            type="url"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <!-- Modal insert image for options -->
    <b-modal
      id="modal-insert-menjodohkan"
      ref="modal"
      centered 
      @ok="saveImageMenjodohkan"
    >
      <form ref="form">
        <b-form-group
          label="Input image URL"
          label-for="image-input"
        >
          <b-form-input
            id="image-input"
            v-model="img_url_jodoh"
            required
            type="url"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <!-- MODAL RECORD VOICE -->
    <b-modal id="rec-voice" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
        <div class="mt-2">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h3>Add Sound</h3>
            <img src="@/assets/images/icons/ic_close_grup.svg" @click="$bvModal.hide('rec-voice')" alt="close" class="img-fluid" type="button">
          </div>
            <vs-tabs :color="colorx">
                <vs-tab label="Upload">
                     <div class="con-tab-ejemplo upload mt-2" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                        <b-alert variant="danger" :show="showAlertVoice" fade dismissible>
                          {{ responseValidateVoice }}   
                        </b-alert>
                        <label @drop="dragFileVoice($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center">
                          <div v-if="voice_before != null && voice_before != ''" class="w-100">
                              <audio ref="audioUpload" :src="voice_before" controls></audio>
                          </div>
                          <div v-else>
                              <img src="@/assets/images/logo/paper_impor.svg" alt="">
                              <h4 class="font-weight-normal mt-2 text-blue">Choose file</h4>
                              <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                              <h5 class="font-weight-normal text-muted mt-1">format file : .mp3, .wbm, .wav</h5>
                          </div>
                          <input type="file" style="display: none" ref="file" accept=".mp3,audio/*" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="fileUploadVoice($event)"/>
                          <!-- <h5 class="mt-3">{{ typeof formData.file === "string" ? formData.file : filename }}</h5> -->
                      </label>
                    </div>
                </vs-tab>
                <vs-tab label="Record Voice">
                     <div class="con-tab-ejemplo">
                      <div v-if="voice_before != null && voice_before != ''" class="w-100 mt-5 mb-5 d-flex flex-column">
                          <div class="card">
                            <div class="card-body">
                              <audio ref="audioUpload" :src="voice_before" controls></audio>
                            </div>
                          </div>
                          <small style="color:#6D75F6;" class="cursor-pointer text-right" @click="hapusRekaman">hapus rekaman</small>
                      </div>
                      <div v-else class="d-flex flex-column justify-content-center my-3">
                        <div class="text-center my-2">
                          <img src="@/assets/images/icons/ic_effect_music.svg" alt="effect" class="img-fluid">
                        </div>
                        <small class="text-center">{{ recordingMinutes }}.{{ recordingSeconds >= 10 ? recordingSeconds : '0' + recordingSeconds }}</small>
                        <div class="text-center">
                          <img v-if="!isRecording" src="@/assets/images/icons/ic_rec.svg" alt="rec" class="img-fluid" type="button" @click="startRecording">
                          <img v-else src="@/assets/images/icons/ic_stop_rec.svg" alt="stop" class="img-fluid" type="button" @click="stopRecording">
                        </div>
                        <p v-if="!isRecording" class="mb-0 font-weight-bold text-center">Record</p>
                        <p v-else class="mb-0 font-weight-bold text-center">Stop</p>
                      </div>
                    </div>
                </vs-tab>
            </vs-tabs>
            <hr>
            <div class="float-right">
                <button v-if="isRecording" class="btn btn-secondary btn-md btn-grey text-dark mr-2 cursor-disabled" disabled><p class="mb-0 mx-2">Cancel</p></button>
                <button v-else class="btn btn-secondary btn-md btn-grey text-dark mr-2" @click="cancelUploadVoice"><p class="mb-0 mx-2">Cancel</p></button>
                <button v-if="isRecording" class="btn btn-purple btn-md text-white cursor-disabled" disabled><p class="mb-0 mx-2">Use</p></button>
                <button v-else class="btn btn-purple btn-md text-white" @click="useVoice"><p class="mb-0 mx-2">Use</p></button>
            </div>
        </div>
    </b-modal>
    
    <!-- MODAL INSERT VIDEO -->
    <b-modal id="insert-video" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
        <div class="mt-2">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h3>Add Video</h3>
            <img src="@/assets/images/icons/ic_close_grup.svg" @click="$bvModal.hide('insert-video')" alt="close" class="img-fluid" type="button">
          </div>
          <div class="">
            <label for="urlVideo">Paste Youtube link here</label>
            <input @keyup.enter="insertLink" type="text" v-model="link_video" class="form-control mb-2" placeholder="https://www.youtube.com/embed/XXXX" id="urlVideo">
            <iframe v-if="video != null" width="100%" height="315" :src="video" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div v-else class="preview-video mt-3 d-flex align-items-center justify-content-center">
              <h5 class="font-weight-normal">Preview Video</h5>
            </div>
          </div>
          <hr>
          <div class="float-right">
              <button class="btn btn-secondary btn-md btn-grey text-dark mr-2" @click="cancelVideo" ><p class="mb-0 mx-2">Cancel</p></button>
              <button v-if="video != null" class="btn btn-purple btn-md text-white" @click="useVideo"><p class="mb-0 mx-2">Use</p></button>
              <button v-else class="btn btn-purple btn-md text-white cursor-disabled" disabled><p class="mb-0 mx-2">Use</p></button>
          </div>
        </div>
    </b-modal>
  </section>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Stats from "stats.js";
import Swal from 'sweetalert2';
import {AlertUtils} from '@/mixins/AlertUtils';

// import { addQuestions } from '@/services/question/question.service'
import $ from "jquery";

import CreatorTextEditor from '@/components/creator-text-editor/CreatorTextEditor.vue';

export default {
  mixins: [AlertUtils],
  components: {
    CreatorTextEditor,
  },
  data() {
    return {
      container: null,
      scene: null,
      camera: null,
      controls: null,
      renderer: null,
      stats: null,
      mixers: [],
      clock: null,
      opsi1: null,
      opsi2: null,
      opsi3: null,
      opsi4: null,
      link_video: null,
      tipeQuiz: "pg",
      additional : false,
      listQuestion:[],
      newListQuestion:[],
      newQuestion : [],
      colorx: 'rgb(188, 59, 242)',
      cobainput : null,
      formData: {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
        index : 1
      },
      listNewQuestion :[],
      maxSoal : 20,
      currentSoal : 1,
      viewSoal : 0,
      image_url : '',
      jodohkanimg1create : null,
      jodohkanimg2create : null,
      jodohkanimg3create : null,
      jodohkanimg4create : null,
      img_url_jodoh : '',

      jodohkantext1create : null,
      jodohkantext2create : null,
      jodohkantext3create : null,
      jodohkantext4create : null,
      jodohkanIndex : null,
      question_isian_singkat : null,
      users:{},
      responseValidateVoice: null,
      showAlertVoice: false,
      voice_before : null,
      mediaRecorder: null,
      recordingMinutes: 0,
      recordingSeconds: 0,
      startTime: null,
      intervalRecord: null,
      isRecording: false,
      video: null,
      lengthQuestionAI: localStorage.getItem('lengthQuestionAi') != null || localStorage.getItem('lengthQuestionAi') != undefined ? localStorage.getItem('lengthQuestionAi') : 0,
    };
  },
  watch : {
    question_isian_singkat(value){
      this.$store.state.createData.option_1 = this.isianSingkatSplit(value)
      this.$store.state.createData.question = value;
      if(this.$store.state.createData.option_1 != null && this.$store.state.createData.option_1 != "" && this.$store.state.createData.question != "" && this.$store.state.createData.question != null) {
        this.$store.state.opsi1 = true
      } else {
        this.$store.state.opsi1 = false
      }
    }
  },
  methods: {
    init() {
      // set container
      this.container = this.$refs.sceneContainer;

      // add stats
      this.stats = new Stats();
      this.clock = new THREE.Clock();
      this.container.appendChild(this.stats.dom);

      // add camera
      const fov = 5.5; // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 1000; // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 2, 12);
      this.camera = camera;

      // create scene
      this.scene = new THREE.Scene();

      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        1 // intensity
      );
      const mainLight = new THREE.DirectionalLight(0xffffff, 4.0);
      mainLight.position.set(10, 10, 10);
      this.scene.add(ambientLight, mainLight);

      // add controls
      this.controls = new OrbitControls(this.camera, this.container);
      // this.controls.enabled = false
      this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI * 0.45;
      this.controls.enableZoom = false; //To enable zoom mode on 3d

      // create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect =
        this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );

      //load model

      const loader = new GLTFLoader();

      const onLoad = (result, position) => {
        const model = result.scene.children[0];
        model.position.copy(position);
        model.scale.set(0.5, 0.5, 0.5);

        const mixer = new THREE.AnimationMixer(model);
        this.mixers.push(mixer);

        const animation = result.animations[0];
        const action = mixer.clipAction(animation);
        action.play();

        this.scene.add(model);
      };

      const parrotPosition = new THREE.Vector3(0, -0.55, 0);
      loader.load(
        "/three-assets/scene.glb",
        (gltf) => onLoad(gltf, parrotPosition),
        undefined,
        undefined
      );

      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    },

    update() {
      const delta = this.clock.getDelta();
      this.mixers.forEach((mixer) => mixer.update(delta));
    },

    render() {
      // this.renderer.setSize(window.screen.width, window.screen.height)
      this.renderer.render(this.scene, this.camera);

    },

    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },
    
    addIndex(){
      var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id


      if(this.$store.state.createData.question_type == 'True or False'){
        this.$store.state.createData.option_3 = null
        this.$store.state.createData.option_4 = null
      }

      if(this.$store.state.createData.question_type == 'Match'){
        this.$store.state.createData.point_match_1 = '10'
        this.$store.state.createData.point_match_2 = '10'
        this.$store.state.createData.point_match_3 = '10'
        this.$store.state.createData.point_match_4 = '10'
      }

      if(this.$store.state.opsi1 != null){
        this.$store.state.createData.correct_answer = "1" 
      } else if(this.$store.state.opsi2 != null){
        this.$store.state.createData.correct_answer = "2" 
      } else if(this.$store.state.opsi3 != null){
        this.$store.state.createData.correct_answer = "3" 
      } else if(this.$store.state.opsi4 != null){
        this.$store.state.createData.correct_answer = "4" 
      } else {
        this.$store.state.createData.correct_answer = null;
      }

      this.$store.state.listCreateQuestion = this.$store.state.listCreateQuestion || [];
      
      if(this.$store.state.createData._id != null){ 
        // Update data on localstorage
        const updateQ = this.$store.state.listCreateQuestion.find(e => e._id === this.$store.state.createData._id)
        localStorage.setItem("listQuestionCreate", JSON.stringify(updateQ));
      } else {
        if(this.$store.state.createData.questions!= null || this.$store.state.createData.question != ''){

          this.$store.state.createData._id = num //set dummy id for edit on local storage
      
          this.$store.state.listCreateQuestion.push(this.$store.state.createData)
          this.$store.state.newListCreateQuestion.push(this.$store.state.createData)

          this.newQuestion.push(this.$store.state.createData)

          localStorage.setItem("listQuestionCreate", JSON.stringify(this.$store.state.listCreateQuestion));
          localStorage.setItem("questionNew", JSON.stringify(this.newQuestion));

          // localStorage.setItem("newListQuestionCreator", JSON.stringify(this.$store.state.newListCreateQuestion));
        }
      }

      const indexAlready = this.$store.state.listCreateQuestion.find(element => element.index == this.$store.state.createData.index) || null

      if(indexAlready != null){

        this.$store.state.createCurrentSoal = this.$store.state.listCreateQuestion.length
        this.$store.state.createCurrentSoal += 1;
        
      } else {

        this.$store.state.createCurrentSoal = this.$store.state.createData.index + 1
      }

      this.$store.state.createData = {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        voice: '',
        video: '',
        point: 10,
        duration: 30,
        correct_answer : '',
        match_1: '',
        match_2: '',
        match_3: '',
        match_4: '',
        img_option_1 : '',
        img_option_2 : '',
        img_option_3 : '',
        img_option_4 : '',
        point_match_1: '10',
        point_match_2: '10',
        point_match_3: '10',
        point_match_4: '10',
        question_type: 'Multiple Choice',
        index : this.$store.state.createData.index+1
      }

      this.$store.state.opsi1 = null
      this.$store.state.opsi2 = null
      this.$store.state.opsi3 = null
      this.$store.state.opsi4 = null

      console.log("AFTER " + this.$store.state.createData.index)
    },
   
    renderSoal(index){
      var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id

      
      let newForm = {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        match_1: '',
        match_2: '',
        match_3: '',
        match_4: '',
        voice: '',
        video: '',
        point: 10,
        duration: 30,
        img_option_1 : '',
        img_option_2 : '',
        img_option_3 : '',
        img_option_4 : '',
        correct_answer : '',
        point_match_1: '10',
        point_match_2: '10',
        point_match_3: '10',
        point_match_4: '10',
        question_type: 'Multiple Choice',
        index : this.$store.state.createData.index+1
      }
      this.$store.state.createCurrentSoal = index;
      console.log(this.$store.state.createCurrentSoal)

      if(this.$store.state.createData.question_type == 'True or False'){
        this.$store.state.createData.option_3 = null
        this.$store.state.createData.option_4 = null
      }

      if(this.$store.state.createData.question_type == 'Match'){
        this.$store.state.createData.point_match_1 = '10'
        this.$store.state.createData.point_match_2 = '10'
        this.$store.state.createData.point_match_3 = '10'
        this.$store.state.createData.point_match_4 = '10'
      }

      if(this.$store.state.opsi1 != null){
        this.$store.state.createData.correct_answer = "1" 
      } else if(this.$store.state.opsi2 != null){
        this.$store.state.createData.correct_answer = "2" 
      } else if(this.$store.state.opsi3 != null){
        this.$store.state.createData.correct_answer = "3" 
      } else if(this.$store.state.opsi4 != null){
        this.$store.state.createData.correct_answer = "4" 
      } else {
        this.$store.state.createData.correct_answer = null;
      }

      this.$store.state.listCreateQuestion = this.$store.state.listCreateQuestion || [];
      
      if(this.$store.state.createData._id != null){ 
        // Update data on localstorage
        const updateQ = this.$store.state.listCreateQuestion.find(e => e._id === this.$store.state.createData._id)
        localStorage.setItem("listQuestionCreate", JSON.stringify(updateQ));
      } else {
        if(this.$store.state.createData.questions!= null || this.$store.state.createData.question != ''){

          this.$store.state.createData._id = num //set dummy id for edit on local storage
      
          this.$store.state.listCreateQuestion.push(this.$store.state.createData)
          this.$store.state.newListCreateQuestion.push(this.$store.state.createData)

          this.newQuestion.push(this.$store.state.createData)

          localStorage.setItem("listQuestionCreate", JSON.stringify(this.$store.state.listCreateQuestion));
        }
      }

      this.$store.state.createData = this.$store.state.listCreateQuestion.find(element => element.index == index) || newForm

      if(this.$store.state.createData.question_type == 'Fill in the Blank'){
        this.question_isian_singkat = this.$store.state.createData.question
      }

      if(this.$store.state.createData.correct_answer == '1'){
        this.$store.state.opsi1 = true
        this.$store.state.opsi2 = null
        this.$store.state.opsi3 = null
        this.$store.state.opsi4 = null
      } else if(this.$store.state.createData.correct_answer == '2'){
        this.$store.state.opsi1 = null
        this.$store.state.opsi2 = true
        this.$store.state.opsi3 = null
        this.$store.state.opsi4 = null
      } else if(this.$store.state.createData.correct_answer == '3'){
        this.$store.state.opsi1 = null
        this.$store.state.opsi2 = null
        this.$store.state.opsi3 = true
        this.$store.state.opsi4 = null
      } else if(this.$store.state.createData.correct_answer == '4'){
        this.$store.state.opsi1 = null
        this.$store.state.opsi2 = null
        this.$store.state.opsi3 = null
        this.$store.state.opsi4 = true
      } else {
        this.$store.state.opsi1 = null
        this.$store.state.opsi2 = null
        this.$store.state.opsi3 = null
        this.$store.state.opsi4 = null
      }

      console.log("REMDER index" + this.$store.state.listCreateQuestion.length)

    },
    
    showAlert(){
      Swal.fire('Hanya bisa membuat 20 Soal !')
    },
    
    tipeQuestion(e){
      console.log(e.target.value)
      if(e.target.value == 'Multiple Choice'){
        this.$store.state.createData.option_1 = ''
        this.$store.state.createData.option_2 = ''
        this.$store.state.createData.option_3 = ''
        this.$store.state.createData.option_4 = ''
      } else if(e.target.value == 'True or False'){
        this.$store.state.createData.option_1 = 'Benar'
        this.$store.state.createData.option_2 = 'Salah'
        this.$store.state.opsi1 = null
        this.$store.state.opsi2 = null
        this.$store.state.opsi3 = null
        this.$store.state.opsi4 = null
      } else if(e.target.value == 'Fill in the Blank') {
        this.question_isian_singkat = this.$store.state.createData.question
        this.$store.state.opsi1 = null
        this.$store.state.opsi2 = null
        this.$store.state.opsi3 = null
        this.$store.state.opsi4 = null
        this.$store.state.createData.option_1 = ''
        this.$store.state.createData.option_2 = ''
        this.$store.state.createData.option_3 = ''
        this.$store.state.createData.option_4 = ''
      } else if(e.target.value == 'Match'){
        this.$store.state.createData.option_1 = ''
        this.$store.state.createData.option_2 = ''
        this.$store.state.createData.option_3 = ''
        this.$store.state.createData.option_4 = ''
      }
    },
    saveImage(){
      this.$store.state.createData.image_url = this.image_url
      this.image_url = ''
    },

    inserImgMenjodohkan(index){
      this.jodohkanIndex = index;
      this.$bvModal.show('modal-insert-menjodohkan')
    },

    saveImageMenjodohkan(){
      if(this.jodohkanIndex == 1){
        this.$store.state.createData.img_option_1 = this.img_url_jodoh
        this.img_url_jodoh = ''
        this.jodohkanIndex = null
      } else if(this.jodohkanIndex == 2){
        this.$store.state.createData.img_option_2 = this.img_url_jodoh
        this.img_url_jodoh = ''
        this.jodohkanIndex = null
      } else if(this.jodohkanIndex == 3){
        this.$store.state.createData.img_option_3 = this.img_url_jodoh
        this.img_url_jodoh = ''
        this.jodohkanIndex = null
      } else if(this.jodohkanIndex == 4){
        this.$store.state.createData.img_option_4 = this.img_url_jodoh
        this.img_url_jodoh = ''
        this.jodohkanIndex = null
      }
    },

    deleteImage(){
      this.$store.state.createData.image_url = null
      console.log("deleted ", this.$store.state.createData.image_url)
    },

    cekQuestionLenght(e){
      const value = e
      const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 120) {
        this.$store.state.showAlertSoalCreate = false
      } else {
        this.$store.state.showAlertSoalCreate = true
      }
    },

    lengthQuestion(e){
      if(e != undefined){
        const value = e
        const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },

    cekAnswer1Length(e){
      const value = e
      const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer1Create = false
      } else {
        this.$store.state.showAlertAnswer1Create = true
      }
    },

    lengthAnswer1(e){
      if(e != undefined){
        const value = e
        console.log(value)
        const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },

    cekAnswer2Length(e){
      const value = e
      const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer2Create = false
      } else {
        this.$store.state.showAlertAnswer2Create = true
      }
    },

    lengthAnswer2(e){
      if(e != undefined){
        const value = e
        const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },

    cekAnswer3Length(e){
      const value = e
      const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer3Create = false
      } else {
        this.$store.state.showAlertAnswer3Create = true
      }
    },

    lengthAnswer3(e){
      if(e != undefined){
        const value = e
        const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },

    cekAnswer4Length(e){
      const value = e
      const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer4Create = false
      } else {
        this.$store.state.showAlertAnswer4Create = true
      }
    },

    lengthAnswer4(e){
      if(e != undefined){
        const value = e
        const regex = value.toString().replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },


    isianSingkatSplit(e){
      let value = e
      let replaceValue = value.toString().replace(/[{}]/g, '_')
      let splitedValue = replaceValue.split("_")
      let finalValue = null

       if(splitedValue[3] != undefined){
        finalValue = splitedValue[1] + ", " + splitedValue[3]

        return finalValue
      } else if(splitedValue[1] != undefined){
        finalValue = splitedValue[1]

        return finalValue
      }
    },

    dragFileVoice(e) {
        const file = [...e.dataTransfer.files];
        let size = file[0].size;
      
        if(size > 1000000){
          this.alertFail(this.$t("file-size-max-1mb"));
        } else {
          this.convertSound(file[0], 'voice')
        }
    },

    fileUploadVoice(e){
      let size = e.target.files[0].size;
      
      if(size > 1000000){
        this.alertFail(this.$t("file-size-max-1mb"));
      } else {
        this.convertSound(e.target.files[0], 'voice')
      }
    },

    convertSound(file, form) {
      var vm = this;
      var reader = new FileReader();
      reader.onloadend = function () {
          switch (form) {
              case 'voice':
                  vm.voice_before = reader.result
                  console.log(vm.voice_before)
                  break;
              default:
                  break;
          }
      };
      reader.readAsDataURL(file);
    },

    startRecording() {
      // Check if the browser supports the MediaRecorder API
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        this.isRecording = true;
        // Get the user's audio stream
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          // Create a new MediaRecorder object and start recording
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();

          // Set the start time and start updating the recording duration at a regular interval
          this.startTime = Date.now();
          this.intervalId = setInterval(() => {
            const elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
            this.recordingMinutes = Math.floor(elapsedTime / 60);
            this.recordingSeconds = elapsedTime % 60;
          }, 1000);
        });
      }
    },
    stopRecording() {
      // Stop the interval and reset the start time
      clearInterval(this.intervalId);
      this.startTime = null;

      // Stop the recording and get the recorded data
      this.mediaRecorder.stop();
      this.mediaRecorder.addEventListener("dataavailable", event => {
        // The recorded data is stored in the event.data Blob
        this.convertSound(event.data, 'voice')
      });
        this.isRecording = false;
    },

    useVoice(){
      this.$store.state.createData.voice = this.voice_before;
      this.$store.state.createData.question = 'Voice'
      this.$store.state.createData.video = null;
      this.recordingMinutes= 0,
      this.recordingSeconds= 0,
      this.isRecording = false;
      this.$bvModal.hide('rec-voice')
      this.voice_before = null;
    },

    cancelUploadVoice(){
      this.voice_before = null;
      this.isRecording = false;
      this.recordingMinutes= 0,
      this.recordingSeconds= 0,
      this.$bvModal.hide('rec-voice')
    },

    hapusRekaman(){
      this.recordingMinutes= 0,
      this.recordingSeconds= 0,
      this.voice_before = null;
    },

    deleteQuestionVoice(){
      this.$store.state.createData.voice = null;
      this.$store.state.createData.question = ''
    },

    useVideo(){
      this.$store.state.createData.video = this.video;
      this.$store.state.createData.voice = null;
      this.$store.state.createData.question = 'Video';
      this.video = null;
      this.link_video = null;
      this.$bvModal.hide('insert-video')
    },

    cancelVideo(){
      this.video = null;
      this.link_video = null;
      this.$bvModal.hide('insert-video')
    },

    deleteQuestionVideo(){
      this.$store.state.createData.video = null;
      this.$store.state.createData.question = ''
    },

    insertLink(){
      const videoId = this.link_video.split('v=')[1]
      const originalLink = this.link_video.split('/')
      if(videoId != undefined){
        this.video = 'https://www.youtube.com/embed/' + videoId
      } else if (originalLink[3] != 'embed') {
        this.video = 'https://www.youtube.com/embed/' + originalLink[3]
      } else {
        this.video = this.link_video
      }
    }
    
  },
  computed: {
    hasAdditional(){
      console.log(this.opsi1)
        return this.opsi1.length >0;
    },

  },
  mounted() {
    $("section.create-quiz-wrapper").parent().addClass("m-0");

    this.setSideBar();
    // this.init();
    // let data = localStorage.getItem("listQuestionCreator");
    let data = localStorage.getItem("listQuestionCreate");
    if(data){
      this.$store.state.listCreateQuestion = JSON.parse(data);
    } else {
      this.$store.state.listCreateQuestion = []
    }

    if(localStorage.getItem('users')){
        this.users = JSON.parse(localStorage.getItem('users'))
        console.log(this.users)
    }

    let index = 1;
    this.$store.state.listCreateQuestion.forEach((el) => {
      el.index = index
      index++;
    })

    this.$store.state.createData.index = index
    console.log(this.$store.state.createData.index)

    var index_id = this.$route.query.index
    if(index_id != undefined){
      this.$store.state.createCurrentSoal = index_id
      this.renderSoal(this.$store.state.createCurrentSoal)
    } else {
      this.$store.state.createCurrentSoal = index;
      console.log(this.$store.state.createCurrentSoal)
    }

    if(this.$store.state.createData.correct_answer == '1'){
      this.$store.state.opsi1 = true
      this.$store.state.opsi2 = null
      this.$store.state.opsi3 = null
      this.$store.state.opsi4 = null
    } else if(this.$store.state.createData.correct_answer == '2'){
      this.$store.state.opsi1 = null
      this.$store.state.opsi2 = true
      this.$store.state.opsi3 = null
      this.$store.state.opsi4 = null
    } else if(this.$store.state.createData.correct_answer == '3'){
      this.$store.state.opsi1 = null
      this.$store.state.opsi2 = null
      this.$store.state.opsi3 = true
      this.$store.state.opsi4 = null
    } else if(this.$store.state.createData.correct_answer == '4'){
      this.$store.state.opsi1 = null
      this.$store.state.opsi2 = null
      this.$store.state.opsi3 = null
      this.$store.state.opsi4 = true
    } else {
      this.$store.state.opsi1 = null
      this.$store.state.opsi2 = null
      this.$store.state.opsi3 = null
      this.$store.state.opsi4 = null
    }
  },
};
</script>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.create-quiz-wrapper {
  background-image: url("../../../assets/images/background/bg-space-web.png");
  min-height: 120%;
  /* background-image: 100% auto; */
  width: 100%;
  background-size: cover;
}
#scene-container {
  width: 120%;
  height: 114.8%;
  position: absolute;
  left: -40vw;
  top: 5vh;
}
#scene-container div {
  display: none !important;
}
.container-filter {
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(12, 0, 0, 0.3);
  position: relative;
}
.container-question {
  width: 100%;
}
.question {
  top: 0%;
  left: 30%;
  width: 70%;
  height: 100%;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.question-text {
  position: relative;
  padding: 15px;
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}
.question-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -42px;
  top: 17px;
  /* border: 10px solid transparent;
    border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
  /* border-right: 32px solid rgba(193,193,193,0.5);  */
  z-index: 2;
}
.question-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -45px;
  top: 50px;
  border: 15px solid transparent;
  border-right: 30px solid rgba(12, 0, 0, 0.6);
  z-index: 3;
  display: none;
}

.choice {
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}


.true-choice {
  background: rgba(0, 220, 25, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}

.choice-menjodohkan{
  height: 14rem;
  width : 15rem;
  border-radius: 30px;
}

.choice-menjodohkan input{
  background: transparent;
  border: 0px;
  color: white;
  text-align: center;
}

.container-question ::placeholder {
   text-align: center;
   color: white; 
}

/* or, for legacy browsers */

.container-question ::-webkit-input-placeholder {
   text-align: center;
   color: white;
}

.container-question :-moz-placeholder { /* Firefox 18- */
   text-align: center;
   color: white;  
}

.container-question ::-moz-placeholder {  /* Firefox 19+ */
   text-align: center; 
   color: white; 
}

.container-question:-ms-input-placeholder {  
   text-align: center; 
   color: white;
}

.bg-green{
  background-color:#369D06 !important
}

.bg-blue{
  background-color:#2B87E3 !important
}

.checkbox-choice {
  right: 5px;
  top: 18px;
}

.subNav {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 15%;
  top: 0%;
  z-index: 2;
}

.subNav-text {
  position: relative;
  padding: 65px;
  margin-bottom: 10px;
}

.subNav-text input::-webkit-input-placeholder {
  text-align: center;
}

.morphism {
  background: rgba(255, 255, 255, 0.3);
  border: rgba(255, 255, 255, 0.3);
}
.morphism:disabled, .morphism {
  background: rgba(255, 255, 255, 0.3);
  border: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}
.form-control::placeholder {
  color: #ffffff;
}



.w-custom {
  width: 15% !important;
}
</style>

<style scoped>
.child-nav input::-webkit-input-placeholder {
  text-align: center;
}

.img-question{
  width: 7rem;
  height: 7rem;
}

.p-question{
  padding: 15px 20px !important;
}

.feat-button{
  width: 37px;
  height: 37px;
}

.btn-impor-custom{
  background-color: #E5E5E5;
  border: 1px solid #D9D9D9;
  height: 14rem;
}

.btn-grey{
    background-color: #C4C4C4 !important;
    border: 0px !important;
}

.btn-purple {
    background-color: #6D75F6 !important;
    border: 1px solid #6D75F6 !important;
}

.feat-rec{
  width: 43px;
  height: 43px;
}

.cursor-disabled{
  cursor: not-allowed !important;
}

.preview-video{
  width: 100%;
  height: 315px;
  background-color:#E5E5E5;
  border-radius: 8px;
}
</style>
